import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import Fab from '@material-ui/core/Fab';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HotelIcon from '@material-ui/icons/Hotel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import TrainIcon from '@material-ui/icons/Train';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Layout from '../../../../../components/layout'
import SEO from '../../../../../components/seo'
import Contents from '../../../../../components/contents'
import Paragraphs from '../../../../../components/paragraph'
import Heading from '../../../../../components/heading'
import HeroBox from '../../../../../components/herobox'

import crest from '../../../../../assets/crest/takeda/white/48.png'

const useStyles = makeStyles(theme => ({
    root: {

    },
    root2: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    media: {
    height: 0,
    paddingTop: '56.25%',
    // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#48bd96",
    '& > img': {
        marginBottom: '0',
    },
  },
   crest: {

    },
  clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    clipAvatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        }
    },
    contentRoot: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    imageContainer: {
        margin: '30px 15px',
    },
    gridContainer: {
        padding: '0px',
        margin: '30px 15px',
    },
    heroImageSection: {
        padding: '0px',
        margin: '0px',
    }
}));

const IsawaonsenStationTakeda24GeneralsPage = ({ data }) => {
    const classes = useStyles();
    const language = 'en';
    const slug = 'station/isawaonsen/takeda-24-generals';
    const pageTitle = 'Lodging of Twenty-Four Samurai of Takeda Shingen';
    const takedaShingen1 = 'Takeda Shingen';
    const takedaShingen2 = 'About Takeda Shingen';
    const takedaShingen3 = 'Outstanding Qualities of Takeda Shingen';
    const pageSubtitle = 'Twenty-Four Samurai of Takeda Shingen';
    const notChild = false;
    const child = true;

    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            {/* <BackgroundImg
                Tag="section"
                fluid={data.hero.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
                style={{
                    // minHeight: '80vh',
                    minHeight: '550px',
                    backgroundSize: 'auto',
                }}
            ></BackgroundImg> */}
            {/* <HeroBox
                image={data.hero.childImageSharp.fluid}
                stationName=""
                descriptions=""
                language={language}
            /> */}
            <section className={classes.heroImageSection}>
                <Img objectFit="contain" fluid={data.hero.childImageSharp.fluid} />
            </section>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title={pageTitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>Only until 2022!</p>
                            <p>Special event celebrating the 500th anniversary of the birth of Takeda Shingen.</p>
                            <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.flags.childImageSharp.fluid} /></div>
                            <p>The event honors the Twenty-Four Samurai who supported Takeda Shingen.</p>
                            <p>Each participating Isawa-Onsen lodging introduces one of the Twenty-Four Samurai.</p>
                            <p>You can enjoy a unique experience going back to the time of the samurai.</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen1} isChild={notChild} />
                        {/* <div className={classes.gridContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Img objectFit="contain" fixed={data.shingen.childImageSharp.fixed} />
                                </Grid>
                            </Grid>
                        </div> */}
                        <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.shingen.childImageSharp.fluid} /></div>
                    </section>
                    <section>
                        <Heading title={takedaShingen2} isChild={child} />
                        <div className={classes.contentRoot}>
                            <p>Thought to be the strongest samurai warlord.</p>
                            <p>Fought 72 battles with a record of 49 wins,3 losses, and 20 draws.</p>
                            <p>No.1 winning percentage</p>
                            <p>He even defeated Tokugawa Ieyasu.</p>
                            <p>His army of 2,000 soldiers once defeated 50,000 soldiers.</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen3} isChild={child} />
                        <div className={classes.contentRoot}>
                            <dl>
                                <dt>Leadership</dt>
                                <dd>Not make decisions by himself. Discuss matters with vassals of Takeda clan. Considers the opinions of others before making a decision.</dd>
                                <dt>Master at gathering information</dt>
                                <dd>Developed his strategy from information collected all over Japan by his spies.</dd>
                                <dt>Chose the right person for each position</dt>
                                <dd>Even a peasant could work as a samurai if he had ability.</dd>
                                <dt>Maximized abilities of subordinates</dt>
                                <dd>Listened to others. Encouraged everyone to give their opinions at war meetings.</dd>
                            </dl>
                        </div>
                    </section>
                    <section>
                        <Heading title={pageSubtitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>Samurai who served as the "brains" of Takeda Shingen</p>
                            <p>Known as the strongest and most capable group of warriors in Japan.</p>
                        </div>
                        <div className={classes.gridContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="高坂昌信"
                                            subheader="Kosaka Masanobu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.kousaka.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Kosaka Masanobu"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>Handsome young man favored by Shingen.</p>
                                                <p>A fierce warrior who was also able to quickly see when it was best to retreat and save his forces. Known as Nige Danjo ("The Fleeing Danjo").</p>
                                                <p>After the death of Takeda Shingen, Kosaka Masanobu continued to serve the Takeda Clan. He was one of the "Big Four" among the Twenty-Four Samurai.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/kikori/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Ryokan Kikori"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="小幡虎盛"
                                            subheader="Obata Toramori"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.obata.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Obata Toramori"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>He was very brave and known as Oni Tora ("Ogre Tiger"). Master of the spear.</p>
                                                <p>Because of his great war record, he served both Nobutora and Shingen.</p>
                                                <p>During battle, Toramori stood in the front line and led his soldiers.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/mine/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Japanese-style Ryokan Mi-ne"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="甘利虎泰"
                                            subheader="Amari Torayasu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.amari.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Amari Torayasu"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>Along with Itagaki Nobukata, he was one of the top two among the Twenty-Four Samurai.</p>
                                                <p>Participated in planning most of the battles for Nobutora and Shingen.</p>
                                                <p>Torayasu was a very brave samurai. As old documents show, when the Amari Force headed to the battle field, enemies would flee.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/ukai/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Hotel Ukai"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="土屋昌続"
                                            subheader="Tsuchiya Masatsugu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.tsuchiya.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Tsuchiya Masatsugu"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>Close adviser of Shingen. Even during fierce battles, would protect Shingen at his headquarters at all times.</p>
                                                <p>His first battle was when he was 17 years old. After only 5 years, he was promoted to general.</p>
                                                <p>He had both scholarly and martial ability.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/hatta/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Hotel Hatta"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="馬場信春"
                                            subheader="Baba Nobuharu"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.baba.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Baba Nobuharu"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>During forty years of battle experiences, Harunobu fought 70 battles and was never wounded once.</p>
                                                <p>He was respectfully called "The Invulnerable Samurai."</p>
                                                <p>He served three generations of the Takeda family; Shigetora, Shingen, and Katsuyori.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/kanpoisawa/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Kanpo No Yado Isawa"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="今福閑斎"
                                            subheader="Imafuku Kansai"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.imafuku.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="Imafuku Kansai"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>Chief retainer of the Takeda family, which was the strongest samurai clan.</p>
                                                <p>He was accomplished in both scholarly and martial pursuits. His scholarly ability made him well-suited for administrative work.</p>
                                                <p>His castle Kunou-jo was considered impregnable.</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/en/hotel/yamanashi/heian/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="Hotel Heian"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </Box>
            </Container>
        </Layout>
        // <pre>{JSON.stringify(data, null, 4)}</pre>
    )
}

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flags:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/flags.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    panel:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/panel.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shingen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/takeda-shingen.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kousaka:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/kousaka-masanobu2.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amari:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/amari.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    baba:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/baba.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imafuku:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/imafuku.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    obata:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/obata.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tsuchiya:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/tsuchiya.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IsawaonsenStationTakeda24GeneralsPage